<template>
  <div>
    <b-card-header class="pb-50">
      <h5>Filtros</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="6" class="mb-1">
          <dynamic-select
            id="customerName"
            label="Cliente"
            placeholder="Digite o nome do cliente"
            v-model="customer"
            :options="customers"
            :loading="loading.customers"
            @find="findCustomers"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Empresa" label-for="searchPartnerCompany">
            <v-select
              id="searchPartnerCompany"
              multiple
              v-model="partnerCompany"
              :reduce="(partner_company_list) => partner_company_list.id"
              :options="partnerCompanies"
              :loading="loading.partnerCompanies"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Produto" label-for="searchProduct">
            <v-select
              id="searchProduct"
              multiple
              v-model="product"
              :reduce="(product_list) => product_list.key"
              :options="commissionProducts"
              :loading="loading.products"
              label="name"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="Parceiro de negócio (PN)"
            label-for="searchConsultant"
          >
            <v-select
              id="searchConsultant"
              multiple
              v-model="consultant"
              :reduce="(consultant_list) => consultant_list.id"
              :options="consultantList"
              :loading="loading.consultantList"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
              @input="setStructureOption"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="Tipo de visualização"
            label-for="searchStructureOption"
          >
            <v-select
              id="searchStructureOption"
              v-model="structureOption"
              :reduce="(structure_list) => structure_list.key"
              :options="structures"
              :loading="loading.structures"
              label="name"
              @keyup.enter="search"
              :disabled="isStructureDisabled"
              :clearable="false"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group label="Franqueado" label-for="searchFranchise">
            <v-select
              id="searchFranchise"
              multiple
              v-model="franchise"
              :reduce="(franchise_list) => franchise_list.id"
              :options="franchises"
              :loading="loading.franchises"
              :close-on-select="false"
              label="name"
              @keyup.enter="search"
              @input="setStructureOption"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="mb-1">
          <b-form-group
            label="Solução financeira"
            label-for="searchFinancialSolution"
          >
            <all-select
              id="searchFinancialSolution"
              v-model="financialSolution"
              :options="financialSolutions"
              label="name"
              take-this="id"
              track-by="id"
              multiple
              :key="clearSelect"
              :close-on-select="false"
              :loading="loading.financialSolutions"
            ></all-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="Nível" label-for="searchTeam">
            <v-select
              id="searchRole"
              multiple
              v-model="role"
              :reduce="(role_list) => role_list.id_consultant_role"
              :options="roles"
              :loading="loading.roles"
              :close-on-select="false"
              label="name"
              @input="consultant = []"
              @keyup.enter="search"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="PN especialista" label-for="closer_consultant">
            <v-select
              v-model="specialist_consultant_id"
              :reduce="(specialist_consultant) => specialist_consultant.id"
              :options="specialistConsultants"
              label="name"
              dir="ltr"
              :loading="loading.specialist"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <template v-if="periodType == commissionPeriods.PAST">
          <b-col cols="12" md="3" class="mb-1">
            <b-form-group label="Período" label-for="searchRangeDate">
              <flat-pickr
                id="searchRangeDate"
                v-model="rangeDate"
                class="form-control"
                :config="flatPickrConfig"
                :placeholder="`01 Jan 2020 até 31 Dez ${actualYear}`"
              />
            </b-form-group>
          </b-col>
        </template>

        <b-col cols="12" md="3" style="margin-top: 28px">
          <b-form-group label-for="only-not-conciliated">
            <b-form-checkbox
              v-model="onlyNotConciliated"
              name="only-not-conciliated"
              switch
            >
              Apenas comissões não recebidas
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormRadioGroup,
  BFormRadio,
  BButton,
  BSpinner,
  BPopover,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import VueSlider from "vue-slider-component";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import { OWN, STRUCTURE } from "@/constants/structure_options";
import * as accountTypes from "@/modules/account/store/types";
import {
  APP_BACKOFFICE_ROLE_ID,
  APP_FINANCIAL_ROLE_ID,
  APP_ADMIN_ROLE_ID,
  APP_CUSTOMER_ROLE_ID,
} from "@/constants/auth";
import * as commissionPeriods from "@/constants/commission_periods";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import AllSelect from "@/modules/shared/components/AllSelect";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BSpinner,
    vSelect,
    BCardText,
    BPopover,
    DynamicSelect,
    VueSlider,
    flatPickr,
    AllSelect
  },
  props: {
    search: Function,
    spinnerActive: Boolean,
    periodType: String,
  },
  data() {
    return {
      commissionPeriods,
      loading: {
        partnerCompanies: false,
        products: false,
        customers: false,
        consultantList: false,
        structures: false,
        franchises: false,
        roles: false,
        specialistConsultants: false,
        creditTypes: false,
        financialSolutions: false,
        conciliationOptions: false
      },
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        mode: "range",
        dateFormat: "Y-m-d",
        locale: Portuguese,
        minDate: "",
        maxDate: "",
      },
      customer: undefined,
      partnerCompany: undefined,
      product: undefined,
      consultant: [],
      structureOption: undefined,
      franchise: undefined,
      role: undefined,
      specialist_consultant_id: undefined,
      onlyNotConciliated: undefined,
      creditType: undefined,
      rangeDate: undefined,
      clearSelect: null,
      financialSolution: undefined,
      maxMonths: 12,
      dir: "ltr",
    };
  },
  setup() {
    const actualYear = new Date().getFullYear();
    return { toast: useToast(), actualYear };
  },
  computed: {
    ...mapGetters({
      partnerCompanies: sharedTypes.PARTNER_COMPANIES,
      commissionProducts: types.COMMISSION_PRODUCTS,
      customers: sharedTypes.CUSTOMERS,
      structures: sharedTypes.STRUCTURE_TYPES,
      consultantList: sharedTypes.CONSULTANTS,
      franchises: sharedTypes.FRANCHISES,
      roles: sharedTypes.ROLES,
      specialistConsultants: sharedTypes.SPECIALIST_CONSULTANTS,
      creditTypes: sharedTypes.CREDIT_TYPES,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      user: accountTypes.USER,
      financialSolutions: types.FINANCIAL_SOLUTIONS
    }),
    isStructureDisabled: function () {
      return (
        (this.consultant && this.consultant.length > 0) ||
        (this.franchise && this.franchise.length > 0)
      );
    },
    isPn: function () {
      return (
        this.user?.user_role_id != APP_BACKOFFICE_ROLE_ID &&
        this.user?.user_role_id != APP_FINANCIAL_ROLE_ID &&
        this.user?.user_role_id != APP_ADMIN_ROLE_ID &&
        this.user?.user_role_id != APP_CUSTOMER_ROLE_ID
      );
    },
  },
  mounted() {
    if (this.periodType == commissionPeriods.PAST) {
      var date = new Date();
      date.setFullYear(date.getFullYear() - 1);
      this.flatPickrConfig.minDate = date;
      this.flatPickrConfig.maxDate = "today";
    } else if (this.periodType == commissionPeriods.FUTURE) {
      var date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      this.flatPickrConfig.minDate = "today";
      this.flatPickrConfig.maxDate = date;
    }
    this.structureOption = this.isPn ? OWN : STRUCTURE;
    this.loading.partnerCompanies = true;
    this.getPartnerCompanies()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as empresas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.partnerCompanies = false;
      });
    this.loading.products = true;
    this.getProducts()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os produtos para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.products = false;
      });
    this.loading.franchises = true;
    this.getFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as franquias para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.franchises = false;
      });
    this.loading.structures = true;
    this.loading.role = true;
    this.getRoles()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os níveis de consultores. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.role = false;
      });
    this.loading.specialist = true;
    this.getSpecialistConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os parceiros de negócio especialistas. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.specialist = false;
      });
    this.getStructures()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as estruturas para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.structures = false;
      });

      this.loading.consultantList = true
      this.getConsultants()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.consultantList = false
        })
      this.loading.creditTypes = true
      this.getCreditTypes()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as naturezas para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.creditTypes = false
        })
      this.loading.financialSolutions = true;
      this.getFinancialSolutions()
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as soluções financeiras para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.financialSolutions = false;
        });
  },
  created() {
    if (this.$route.query.consultant) {      
      if (Array.isArray(this.$route.query.consultant)) {
        this.consultant = this.$route.query.consultant.map(id => parseInt(id, 10));
      } else {
        this.consultant = [parseInt(this.$route.query.consultant, 10)];
      }
    }
  },  
  methods: {
    ...mapActions({
      getPartnerCompanies: sharedTypes.GET_PARTNER_COMPANIES,
      getProducts: types.GET_COMMISSION_PRODUCTS,
      getCustomers: sharedTypes.GET_CUSTOMERS,
      getStructures: sharedTypes.GET_STRUCTURE_TYPES,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getFranchises: sharedTypes.GET_FRANCHISES,
      getRoles: sharedTypes.GET_ROLES,
      getSpecialistConsultants: sharedTypes.GET_SPECIALIST_CONSULTANTS,
      getCreditTypes: sharedTypes.GET_CREDIT_TYPES,
      getFinancialSolutions: types.GET_FINANCIAL_SOLUTIONS
    }),
    findCustomers(keyword) {
      this.loading.recommendedBy = true;
      this.getCustomers({ keyword })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os clientes para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.recommendedBy = false;
        });
    },
    setStructureOption() {
      if (
        (this.consultant && this.consultant.length > 0) ||
        (this.franchise && this.franchise.length > 0)
      ) {
        this.structureOption = STRUCTURE;
      }
    },
  },
};
</script>

<style lang="scss">
$themeColor: #f26237;
@import "~vue-slider-component/lib/theme/default.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.form-group {
  margin-bottom: 0;
}
</style>
