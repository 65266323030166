<template>
  <div>
    <!-- Totalizadores -->
    <commissions-totalizer
      :periodType="periodType"
      :totalizer="totalizer"
      :loading="loading"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <!-- Filtros -->
      <commissions-list-filters :periodType="periodType" ref="filters" />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="8">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="isAdmin || isFinancial || isBackoffice"
                variant="outline-primary"
                @click="download()"
                class="mr-1"
                :disabled="loading"
              >
                Baixar relatório analítico
              </b-button>
              <b-button
                variant="outline-secondary"
                @click.prevent="clearSearch"
                class="mr-1"
                :disabled="loading"
              >
                Limpar filtros
              </b-button>
              <b-button
                variant="primary"
                @click.prevent="search(1)"
                :disabled="loading"
              >
                <b-spinner v-if="loading" small />
                <feather-icon v-else icon="SearchIcon" class="mr-50" />
                <span>
                  {{ loading ? "Aguarde..." : "Pesquisar" }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        :items="items"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="Não foram encontradas comissões para esta busca"
        :busy="loading"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
        @row-clicked="goToContractDetails"
      >
        <!-- Column: Cliente -->
        <template #cell(customer_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.customer_name
            }}</span>
          </div>
        </template>

        <!-- Column: PN -->
        <template #cell(owner_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.owner_name
            }}</span>
          </div>
        </template>

        <!-- Column: PN Nível -->
        <template #cell(commission_owner_level)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.commission_owner_level
            }}</span>
          </div>
        </template>

        <!-- Column: Especialista -->
        <template #cell(specialist_consultant_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.specialist_consultant_name
            }}</span>
          </div>
        </template>

        <!-- Column: Especialista Nível -->
        <template #cell(specialist_consultant_level)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.specialist_consultant_level
            }}</span>
          </div>
        </template>

        <!-- Column: Empresa -->
        <template #cell(name_partner)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.name_partner
            }}</span>
          </div>
        </template>

        <!-- Column: Comissão Prevista -->
        <template #cell(expected_amount)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.expected_amount | toCurrency
            }}</span>
          </div>
        </template>

        <!-- Column: Data Prevista -->
        <template #cell(launch_date)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.launch_date | onlyDate
            }}</span>
          </div>
        </template>

        <!-- Column: Comissão Recebida -->
        <template #cell(amount)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.amount | toCurrency
            }}</span>
          </div>
        </template>

        <!-- Column: Data de Recebimento -->
        <template #cell(reconciliation_date)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.invoice_billing_date | onlyDate
            }}</span>
          </div>
        </template>

        <!-- Column: Parcela -->
        <template #cell(payment_parcel)="data">
          <div class="text-wrap text-center">
            <span class="align-text-top text-capitalize">{{
              data.item.payment_parcel
            }}</span>
          </div>
        </template>

        <!-- Column: Solução Financeira -->
        <template #cell(solution_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.solution_name
            }}</span>
          </div>
        </template>

        <!-- Column: Produto -->
        <template #cell(product_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.product_name
            }}</span>
          </div>
        </template>

        <!-- Column: status do contrato -->
        <template #cell(contract_status_name)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.contract_status_name
            }}</span>
          </div>
        </template>

        <!-- Column: Nível -->
        <template #cell(owner_level_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.owner_level_name
            }}</span>
          </div>
        </template>

        <!-- Column: Fatura -->
        <template #cell(invoice_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{
              data.item.invoice_name
            }}</span>
          </div>
        </template>

        <!-- Column: Comentário lançamento -->
        <template #cell(comments_adjustment)="data">
          <div class="text-wrap text-center">
            <span
              v-html="data.item.comments_adjustment"
              class="align-text-top"
            ></span>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} a {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BCardText,
  BMediaAside,
  BMediaBody,
  BCardTitle,
  BButtonGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import CommissionsListFilters from "../components/CommissionsListFilters";
import CommissionsTotalizer from "../components/CommissionsTotalizer";
import { DATE } from "@/constants/commission_periods";
import {
  APP_BACKOFFICE_ROLE_ID,
  APP_FINANCIAL_ROLE_ID,
  APP_ADMIN_ROLE_ID,
} from "@/constants/auth";
import * as structureOptions from "@/constants/structure_options";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as accountTypes from "@/modules/account/store/types";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardText,
    BCardTitle,
    BMediaAside,
    BMediaBody,
    BButtonGroup,
    vSelect,
    CommissionsListFilters,
    CommissionsTotalizer,
  },
  props: {
    year: [Number, String],
    month: [Number, String],
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      loading: false,
      periodType: DATE,
      tableColumns: [
        { key: "customer_name", label: "Cliente", sortable: true },
        { key: "owner_name", label: "PN", sortable: true },
        { key: "commission_owner_level", label: "PN Nível", sortable: true },
        {
          key: "specialist_consultant_name",
          label: "Especialista",
          sortable: true,
        },
        {
          key: "specialist_consultant_level",
          label: "Especialista Nível",
          sortable: true,
        },
        { key: "solution_name", label: "Solução Financeira", sortable: true },
        { key: "product_name", label: "Produto", sortable: true },
        { key: "name_partner", label: "Empresa", sortable: true },
        { key: "expected_amount", label: "Comissão Prevista", sortable: true },
        { key: "launch_date", label: "Data Prevista", sortable: true },
        {
          key: "amount",
          label: "Comissão Recebida",
          sortable: true,
          class: ["p-1"],
        },
        {
          key: "reconciliation_date",
          label: "Data de Recebimento",
          sortable: true,
        },
        { key: "payment_parcel", label: "Parcela", sortable: true },
        {
          key: "contract_status_name",
          label: "Status contrato",
          sortable: true,
        },
        { key: "owner_contract_name", label: "Dono contrato", sortable: true },
        { key: "owner_level_name", label: "Dono nível", sortable: true },
        { key: "invoice_name", label: "Fatura", sortable: true },
      ],
      currentPage: 1,
      itemsPerPage: 1,
      sortField: null,
      order: undefined,
    };
  },
  watch: {
    year() {
      this.clearSearch();
    },
    month() {
      this.clearSearch();
    },
  },
  computed: {
    ...mapGetters({
      searchResult: types.CURRENT_COMMISSIONS_SEARCH_RESULT,
      user: accountTypes.USER,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.searchResult?.data || [];
    },
    totalItems: function () {
      return this.searchResult?.total || 0;
    },
    pageItemsFrom: function () {
      return this.searchResult?.from || 0;
    },
    pageItemsTo: function () {
      return this.searchResult?.to || 0;
    },
    totalizer: function () {
      return this.searchResult?.totalizers || {};
    },
    isFinancial: function () {
      return this.user?.user_role_id == APP_FINANCIAL_ROLE_ID;
    },
    isAdmin: function () {
      return this.user?.user_role_id == APP_ADMIN_ROLE_ID;
    },
    isBackoffice: function () {
      return this.user?.user_role_id == APP_BACKOFFICE_ROLE_ID;
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.search(1);
  },
  methods: {
    ...mapActions({
      searchComissions: types.SEARCH_CURRENT_COMMISSIONS,
      exportReport: types.EXPORT_CONTRACTS_COMISSIONS,
    }),
    search(currentPage) {
      if (!this.$refs.filters) return;
      const {
        customer,
        partnerCompany,
        product,
        consultant,
        structureOption,
        franchise,
        creditType,
        role,
        specialist_consultant_id,
        onlyNotConciliated,
        financialSolution,
      } = this.$refs.filters;
      const { year, month, order, periodType } = this;
      if (!year || !month) return;
      this.currentPage = Number.isInteger(currentPage) ? currentPage : 1;
      this.loading = true;
      this.searchComissions({
        customer,
        partner_company: partnerCompany,
        product,
        consultant,
        structure_option: structureOption,
        franchise,
        credit_type: creditType,
        year,
        month,
        role,
        order,
        periodType,
        specialist_consultant_id,
        financialSolution,
        onlyNotConciliated,
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao buscar as comissões. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sort(event) {
      //clear in third click
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? " DESC" : " ASC";
        this.order = event.sortBy + direction;
        this.sortField = event.sortBy;
      }
      this.search();
    },
    download() {
      const {
        customer,
        partnerCompany,
        product,
        consultant,
        structureOption,
        franchise,
      } = this.$refs.filters;
      const { year, month } = this;
      this.exportReport({
        month: !isNaN(this.$route.params.month)
          ? parseInt(this.$route.params.month)
          : month,
        year: !isNaN(this.$route.params.year)
          ? parseInt(this.$route.params.year)
          : year,
        customer,
        partner_company: partnerCompany,
        product,
        consultant,
        structure_option: structureOption,
        franchise,
      })
        .then((response) => {
          this.$router.push({ name: "commissions" });
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao baixar o relatório. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    goToContractDetails(item, index) {
      this.$router.push({
        name: "contract-details-summary",
        params: { id: item.contract_id },
      });
    },
    clearSearch() {
      this.$refs.filters.customer = undefined;
      this.$refs.filters.partnerCompany = undefined;
      this.$refs.filters.product = undefined;
      this.$refs.filters.consultant = undefined;
      this.$refs.filters.structureOption = structureOptions.OWN;
      this.$refs.filters.franchise = undefined;
      this.$refs.filters.creditType = undefined;
      this.$refs.filters.rangeDate = undefined;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.img_action {
  width: 16px;
  height: 16px;
}

#buttons {
  border: 0px;
}
</style>
